<template>
  <div class="row card m-0">
    <div class="col-12">
      <div class="p-2 d-flex flex-column">
        <div
          v-if="!capacites.filter(({ niveau }) => niveau == 1).length"
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
        <div
          :key="index"
          v-for="(item, index) in capacites.filter(({ niveau }) => niveau == 1)"
        >
          <p class="mt-3 mb-2 h5">{{ item.name }}</p>
          <div
            class="text-white row p-2 rounded my-2 py-3"
            style="background: #f2edf3"
          >
            <div
              v-if="!item.sousCapacites.length"
              class="text-gray d-flex text-center w-100"
              style="height: 50px"
            ></div>
            <div
              class="col-12 col-md-3 mb-2"
              :key="appIndex"
              v-for="(capacite, appIndex) in uniqBy(item.sousCapacites, 'id')"
            >
              <div class="bg-gradient-success rounded h-100 py-2 px-4">
                <b-link
                  class="mb-0 text-white"
                  :to="{
                    name: 'show-infosys-capacity',

                    params: {
                      id: capacite.id,
                      slug: capacite.slug || 'abcde',
                    },
                  }"
                >
                  {{ capacite.name }}
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqBy } from "lodash";
export default {
  props: {
    data: {
      type: Array,
      default: () => [
        {
          title: "Digital Platform",
          items: [
            ["CRM", "Portals", "Channels", "Ext. Relation", "Ext. API"],
            ["Referetial", "Bi / Reporting", "Big Data / IA"],
          ],
        },
        {
          title: "Core business",
          items: [["Retail Bank", "Insurance", "Leasing", "Financial Market"]],
        },
      ],
    },
    capacites: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    uniqBy,
  },
};
</script>

<style></style>
