<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body p-0">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(status)="data">
                <span class="text-primary">{{ data.value }}</span>
              </template>
              <template v-slot:cell(name)="data">
                <b-link
                  :to="{
                    name: 'show-infosys-capacity',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug || 'abcde',
                    },
                  }"
                  >{{ data.value }}</b-link
                >
              </template>

              <template #cell(niveau)="data">
                <span>{{ data.value }}</span>
              </template>

              <template #cell(sousCapacites)="data">
                <b-link
                  :to="{
                    name: 'show-infosys-capacity',

                    params: {
                      id: data.item.parent.id,
                      slug: data.item.parent.slug || 'abcde',
                    },
                  }"
                  v-if="data.item.parent"
                >
                  {{ data.item.parent.name }}</b-link
                >
                <span v-else> -</span>
              </template>

              <template v-slot:cell(applications)="data">
                <div class="text-center">
                  <b-badge
                    v-if="data.value.length"
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    @click="$emit('showGoals', data.item)"
                    >{{ data.value.length || 0 }}</b-badge
                  >
                  <b-badge
                    v-else
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    >{{ data.value.length }}</b-badge
                  >
                </div>
              </template>

              <template #cell(actions)="data">
                <table-actions
                  :actions="isArchitect || isSuper || isAdmin ? ['delete'] : []"
                  :editMode="data.item.hasOwnProperty('editMode')"
                  @showItem="
                    $router.push({
                      name: 'show-infosys-capacity',
                      params: {
                        id: data.item.id,
                        slug: data.item.slug || 'abcde',
                      },
                    })
                  "
                  @deleteItem="deleteItem(data.item.id)"
                />
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { uniqBy } from "lodash";
import { mapGetters } from "vuex";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableActions,
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "name", label: "Capacité Si", sortable: true },
        {
          key: "sousCapacites",
          label: "Capacité de rattachement",
          sortable: true,
        },
        { key: "niveau", label: "niveau", sortable: true },
        {
          key: "applications",
          label: "Applications",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    rows() {
      return this.items.length;
    },
  },
  props: {
    // fields: Array,
    items: Array,
    capacites: Array,
    loading: Boolean,
  },
  methods: {
    uniqBy,
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("capaciteSi/deleteCapacite", id).then(() => {
            this.$store.dispatch("capaciteSi/fetchAllCapacites");
          });

          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
  },
};
</script>
